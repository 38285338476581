import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"
import { FaEnvelope } from 'react-icons/fa'
import { FaPhoneAlt } from 'react-icons/fa'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { Link } from "gatsby"

const ContactPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Contact</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<h2>Restore</h2>
                    <p><a href="https://goo.gl/maps/FJuWrzmLWxypoEFa6" target="_blank" rel="noopener noreferrer"><FaMapMarkerAlt /> 908 Lincoln St, Rhinelander, WI 54501</a></p>
                    <p><a href="tel:7154202301"><FaPhoneAlt /> (715) 420-2301</a></p>
                    <p><a href="mailto:restore@habitatnorthwoods.org"><FaEnvelope /> restore@habitatnorthwoods.org</a></p>
					<div className="spacer"></div>
					<h2>Habitat For Humanity Affiliate</h2>
                    <p><a href="https://goo.gl/maps/FJuWrzmLWxypoEFa6" target="_blank" rel="noopener noreferrer"><FaMapMarkerAlt /> 908 Lincoln St, Rhinelander, WI 54501</a></p>
                    <p><a href="tel:7154202301"><FaPhoneAlt /> (715) 420-0412</a></p>
                    <p><a href="mailto:ed@habitatnorthwoods.org"><FaEnvelope /> ed@habitatnorthwoods.org</a></p>
					<div className="spacer"></div>
					<h2>Newsletter</h2>
					<p>Newsletter to be added to our Quaterly Newsletter mailing, email us at <a href="mailto:mailinglist@habitatnorthwoods.org">mailinglist@habitatnorthwoods.org</a></p>
					<Link className="button" to="/about/newsletters/">Newsletter Archive</Link>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default ContactPage

export const Head = () => (
	<Seo
        title="Contact Page | Habitat For Humanity Northwoods Wisconsin"
    />
)
